import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"

const DemoCloudAPI = () => {
  return (
    <div className="section py-8">
      <div className="container">
        <div className="row justify-content-between align-items-center mb-5">
          <div className="col-12 col-md-7 order-md-1">
            <h2
              className="text-primary font-weight-bold"
              style={{ maxWidth: "450px" }}
            >
              Start for free
            </h2>
          </div>
          <div className="col-12 col-md-4 order-md-2 text-center">
            <a
              href="https://app.celantur.com/" target="_blank"
              className="btn btn-success lift event-start-demo"
            >
              Access API{" "}
              <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <span className="badge badge-lg badge-rounded-circle badge-primary-soft">
                      <span>1</span>
                    </span>
                  </div>
                  <div className="col">
                    <hr className="d-none d-md-block border-gray-900-50 mr-n7" />
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3 className="text-primary">Sign up</h3>
                <p className="text-muted-80 mb-6 mb-md-0">
                  Get API access credentials from <a href="https://app.celantur.com/" target="_blank">app.celantur.com</a>.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <span className="badge badge-lg badge-rounded-circle badge-primary-soft">
                      <span>2</span>
                    </span>
                  </div>
                  <div className="col">
                    <hr className="d-none d-md-block border-gray-900-50 mr-n7" />
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3 className="text-primary">Connect your code</h3>
                <p className="text-muted-80 mb-6 mb-md-0">
                  Authenticate, set anonymization parameters and send images.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <span className="badge badge-lg badge-rounded-circle badge-primary-soft">
                      <span>3</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3 className="text-primary">Automate Image Blurring</h3>
                <p className="text-muted-80 mb-0">
                  Receive your automatically anonymized images.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DemoCloudAPI
