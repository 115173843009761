import React from "react"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FeatureCard = ({ headline, image, icon, iconColor, children, size = "4x" }) => {
	let visual;
	
	visual = image ? (
		<>
			<div className="card-body p-5 pb-0 my-auto">
				<div className="img-fluid mb-5">
					<Img fluid={image} alt={headline} className="mx-auto" />
				</div>
			</div>
		</>
	) : (
		""
	)

	visual = icon ? (
		<>
			<div className="card-body p-5 pb-0 my-auto">
				<FontAwesomeIcon
					icon={icon}
					size={size}
					style={{ color: iconColor ? iconColor : "#4285f4" }}
				/>
			</div>
		</>
	) : (
		""
	)

	// font-gradient
	return (
		<>
			<div className="col-12 col-sm-6 col-lg-4 mt-7">
				<div className="card h-100 rounded-3 shadow-lift bg-dark">
					{visual}
					<div className="card-body p-5">
						<h3 className="fw-bold text-white">{headline}</h3>
						<p className="text-muted mb-0">{children}</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default FeatureCard
