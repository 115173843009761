import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const SuccessStoryCard = ({ title, image, link }) => {
  return (
    <>
      <div className="col-12 col-md-6 d-flex mb-8">
        <div className="card mb-xl-5 shadow-lift">
          <Link to={link}>
            <Img
              fluid={image}
              className="card-img-top"
              style={{ height: "150px" }}
            />
          </Link>

          <div className="card-body">
            <h3 className="font-weight-bold">
              {title}
            </h3>

            <Link
              to={link}
              className="btn btn-sm btn-primary-soft stretched-link"
            >
              Read Success Story
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

SuccessStoryCard.propTypes = {
  name: PropTypes.node.isRequired,
  color: PropTypes.node,
  image: PropTypes.node,
  text: PropTypes.node,
  link: PropTypes.node,
}

export default SuccessStoryCard
