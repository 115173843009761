import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faArrowLeft,
  faEraser,
  faCircleInfo,
  faSection,
  faShieldHalved,
  faObjectGroup,
  faMagnifyingGlass,
  faArrowUpRightDots,
  faCircleCheck,
  faImagePortrait,
  faSliders,
  faLink,
} from "@fortawesome/free-solid-svg-icons"
import ActionForm from "../components/action-form"
import SuccessStoryCard from "../components/success-story-card"
import FeatureCard from "../components/feature-card"
import Customers from "../components/customers"
import DemoCloudAPI from "../components/demo-cloud-api"
import { JobFeature } from "../components/job-components"
import SyntaxHighlighter from "react-syntax-highlighter"
import { atomOneDarkReasonable } from "react-syntax-highlighter/dist/esm/styles/hljs"

const codePostRequest = `# create task to blur faces on an image
config = {
  "anonymization_method": "blur",
  "face": True
}

response = requests.post(
  'https://api.celantur.com/v2/task/', 
  data=json.dumps(config), 
  headers={'Authorization': auth_token}
);`

const ProductAPI = ({ data }) => (
  <Layout>
    <SEO
      title="Image Anonymization via Web API, pay-per-use - Celantur API"
      description="AI-powered blurring of faces, license plates, persons, vehicles on images with a simple REST call. Integrate with ease and pay only what you use, no subscription."
    />

    <section className="mt-n11 pt-12 pb-8 pt-md-14 pb-md-11">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            <SyntaxHighlighter
              language="python"
              style={atomOneDarkReasonable}
              customStyle={{ borderRadius: "10px" }}
              className="shadow-dark"
            >
              {codePostRequest}
            </SyntaxHighlighter>
          </div>
          <div className="col-12 cold-md-7 col-lg-6 order-md-1">
            <h1 className="display-5 text-center text-md-left text-primary font-weight-bold">
              Anonymize images via Web API, without a subscription
            </h1>
            <p className="lead text-center text-md-left mb-5">
              Blur <span className="font-weight-bold">faces</span>,
              <span className="font-weight-bold"> license plates</span>,
              <span className="font-weight-bold"> persons</span> and
              <span className="font-weight-bold"> vehicles</span> on images in seconds with a simple REST call.
            </p>
            <div className="text-center text-md-left">
              <a
                href="https://app.celantur.com/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary lift event-start-demo"
              >
                Start for free <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
              </a>
              <a
                href="https://doc.celantur.com/cloud-api/getting-started"
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-secondary ml-3"
              >
                Read Documentation
              </a>
              <p className="text-muted font-size-sm mt-1">No credit card necessary.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <Customers />
      </div>
    </section>

    <section className="py-8 py-md-11 bg-black bg-dotted">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-7 text-center">
            <h2 className="display-3 fw-bold font-gradient">Everything developers need to anonymize images</h2>
            <p className="lead text-muted mb-9">
              We've been building image anonymization solutions since 2018. Now, we enable every developer to use our
              world-class technology.
            </p>
          </div>
        </div>
        <div className="row mt-n7">
          <FeatureCard headline="Anonymize Personal Data" icon={faImagePortrait} size="3x">
            Faces, Persons, License Plates, Vehicles
          </FeatureCard>
          <FeatureCard headline="Multiple anonymization methods" icon={faEraser} size="3x">
            Choose whether to blur, pixelate or blacken personal data.
          </FeatureCard>
          <FeatureCard headline="Industry-grade Detection Rate" icon={faCircleCheck} size="3x">
            State-of-the-art anonymization, trusted by leading companies.
          </FeatureCard>
          <FeatureCard headline="Webhooks" icon={faLink} size="3x">
            Trigger HTTP callbacks when anonymized images are ready to be fetched.
          </FeatureCard>
          <FeatureCard headline="Metadata" icon={faCircleInfo} size="3x">
            Receive metadata about detected objects (object type, coordinates, ...)
          </FeatureCard>
          <FeatureCard headline="Segmentation Masks" icon={faObjectGroup} size="3x">
            Incorporate generated segmentation masks in your image processing workflows.
          </FeatureCard>
          <FeatureCard headline="Easy Result Analysis" icon={faMagnifyingGlass} size="3x">
            Display bounding boxes and confidence scores directly on images.
          </FeatureCard>
          <FeatureCard headline="Configurable" icon={faSliders} size="3x">
            Customize detection thresholds, blurring appearance and much more.
          </FeatureCard>
          <FeatureCard headline="Privacy Compliance" icon={faSection} size="3x">
            Comply with the GDPR, CCPA, PIPL and other data protection laws.
          </FeatureCard>
          <FeatureCard headline="Secure" icon={faShieldHalved} size="3x">
            Data transfer via HTTPS and processing in GPDR-compliant datacenters in the EU.
          </FeatureCard>
          <FeatureCard headline="Scalable" icon={faArrowUpRightDots} size="3x">
            Anonymize a few or thousands of images with ease.
          </FeatureCard>
        </div>
        <div className="row">
          <div className="alert alert-warning container text-center my-5" role="alert">
            <span role="img" aria-label="arrow-right">
              ➡️
            </span>{" "}
            Deploy the API on your own infrastructure with{" "}
            <strong>
              <Link to="/container/">Celantur Container</Link>
            </strong>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-8">
      <div class="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 text-center mb-5">
            <h2 className="font-weight-bold">Pricing</h2>
            <p className="font-size-lg text-gray-700">
              Pay only for what you use. We don't charge monthly subscription fees.<br />
              Contact us for volume discounts or custom offers.
            </p>
          </div>
        </div>
        <div class="row gx-4">
          <div class="col-12 col-md-6 ">
            <div class="card shadow-blue-green mb-6 mb-md-0">
              <div class="card-body">
                <div class="text-center mb-3">
                  <span className="badge badge-success">Basic</span>
                </div>
                <div class="d-flex justify-content-center">
                  <span class="h2 mb-0 mt-2 pr-1 font-weight-bold font-green">€</span>
                  <span class="price display-2 mb-0 font-weight-bold font-green">0.06</span>
                  <span class="h2 align-self-end mb-1">/image</span>
                </div>
                <p class="text-center text-body-secondary mb-5">up to 32 Megapixel</p>
                <JobFeature>Fair pay-per-use - no subscription</JobFeature>
                <JobFeature>Anonymize Faces, Persons, License Plates, Vehicles</JobFeature>
                <JobFeature>Webhooks</JobFeature>
                <JobFeature>Generate metadata and segmentation masks</JobFeature>
                <JobFeature>Easy result analysis</JobFeature>
                <JobFeature>Secure &amp; GDPR-compliant data processing in the EU</JobFeature>
                <a
                  href="https://app.celantur.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-success w-100 event-start-demo"
                >
                  Start for free <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card shadow-lg mb-6 mb-md-0">
              <div class="card-body">
                <div class="text-center mb-3">
                  <span className="badge badge-primary">Enterprise</span>
                </div>

                <div class="d-flex justify-content-center">
                  <span class="h2 mb-0 mt-2 pr-1">€</span>
                  <span class="price display-2 mb-0">
                    custom
                  </span>
                  <span class="h2 align-self-end mb-1">/image</span>
                </div>

                <p class="text-center text-body-secondary mb-5">all resolutions</p>

                <JobFeature icon={faArrowLeft} bold="true"><strong>Everything in Basic, plus:</strong></JobFeature>
                <JobFeature>Volume discount</JobFeature>
                <JobFeature>Custom support packages</JobFeature>

                <Link to="/contact/" className="btn btn-primary w-100 event-start-demo">
                  Contact Sales <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <DemoCloudAPI />
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <ActionForm source="api" />
      </div>
    </section>

    <section className="my-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 text-center mb-5">
            <h2 className="font-weight-bold">Success Stories</h2>
            <p className="font-size-lg text-gray-700">
              Read how industry-leading companies use Celantur Container to solve their privacy challenges.
            </p>
          </div>
        </div>
        <div className="row">
          <SuccessStoryCard
            title={
              <>
                <mark>Looom</mark> uses Celantur Cloud API for GDPR-compliant AR/VR applications
              </>
            }
            link="/success-story-looom/"
            image={data.looomImage.childImageSharp.fluid}
          />
          <SuccessStoryCard
            title={
              <>
                <mark>Fortune 500 tech company</mark> develops L4 self-driving vehicle technology in a GDPR-compliant
                manner
              </>
            }
            link="/success-story-fortune-500-self-driving/"
            image={data.traffic.childImageSharp.fluid}
          />
        </div>
      </div>
    </section>
  </Layout>
)

export default ProductAPI

export const logo = graphql`
  fragment imageProductPage on File {
    childImageSharp {
      fluid(maxWidth: 640) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    looomImage: file(relativePath: { eq: "looom-pano-garage.jpg" }) {
      ...imageProductPage
    }
    traffic: file(relativePath: { eq: "traffic.jpg" }) {
      ...imageProductPage
    }
  }
`
