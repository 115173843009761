import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const JobFeature = ({ children, icon = faCheck, center = false }) => {
  const justifyContentCenter = center ? `justify-content-center` : ``;
  const classes = `d-flex ${justifyContentCenter}`;

  return (
    <div className={classes}>
      <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
        <FontAwesomeIcon icon={icon} />
      </span>
      <p className="text-gray-800">{children}</p>
    </div>
  )
}

const JobRole = ({ children }) => {
  return (
    <>
      <h3>Your role</h3>
      {children}
    </>
  )
}

const JobRequirements = ({ children }) => {
  return (
    <>
      <h3 className="my-5">Your mind and skill set</h3>
      {children}
    </>
  )
}

const JobSummary = ({
  title,
  link,
  workingTime,
  location,
  startingDate,
  highlight = false,
  children,
}) => {
  const trClass = highlight ? "table-warning" : ""
  return (
    <tr className={trClass}>
      <td>
        <Link to={link} className="text-reset text-decoration-none">
          <p className="mb-1 font-weight-bold">{title} (f/x/m)</p>
          <p className="font-size-sm text-muted mb-0">{children}</p>
        </Link>
      </td>
      <td>
        <Link to={link} className="text-reset text-decoration-none">
          {startingDate}
        </Link>
      </td>
      <td>
        <Link to={link} className="text-reset text-decoration-none">
          <p className="font-size-sm mb-0">
            {workingTime}
            <br />
            {location}
          </p>
        </Link>
      </td>
    </tr>
  )
}

export { JobFeature, JobRole, JobRequirements, JobSummary }
